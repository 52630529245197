
const initialState = {
  data:{},
  roles:{}
}

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {

        case "userdata":
        return {
            ...state,
            data: action.payload.user,
            roles: action.payload.roles,
        }
        
        default: return state
    }
}

export default LoginReducer