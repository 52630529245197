import React from 'react';

const AuthContext = React.createContext();

const OnlineStatusContext = React.createContext(true)

export {OnlineStatusContext}

export default AuthContext;

