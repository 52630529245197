import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import AuthContext,{OnlineStatusContext} from './Context';
import { Provider } from 'react-redux';
import store from './store';
import { useDispatch } from 'react-redux';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
  <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
  );

// Lazy-loaded components
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const SignIn = React.lazy(() => import('./views/pages/Sign_In/Sign_In'));
const Forget = React.lazy(() => import('./views/pages/Forget_pd/Forget_pd'));
const ChangePwd = React.lazy(() => import('./views/pages/Change_pd/Change_pd'));
const SignUp = React.lazy(() => import('./views/pages/Sign_Up/Sign_Up'));

const App = (props) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  const contextConst = useMemo(() => ({
    signIn: (data) => {
      localStorage.setItem("userData", JSON.stringify(data));
      dispatch({ type: "userdata", payload: data });
      setIsAuthenticated(true);
    },
    signOut: (navigate) => {
      dispatch({ type: 'logout' });
      localStorage.removeItem("userData");
      setIsAuthenticated(false);

      navigate('/signIn', { replace: true });

    },
  }), [dispatch]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    console.log("userData",userData)  
    
    if (userData) {
      dispatch({ type: "userdata", payload: userData });
      setIsAuthenticated(true);
    }

  }, [dispatch]);


  const AuthenticatedRoute = ({ element }) => {
    if(isAuthenticated) return element;
  };

  AuthenticatedRoute.propTypes = {
    element: PropTypes.element.isRequired,
  };

  const UnauthenticatedRoute = ({ element }) => {
    return !isAuthenticated ? element : <Navigate to="/" replace />;
  };

  UnauthenticatedRoute.propTypes = {
    element: PropTypes.element.isRequired,
  };

  return (
    <Provider store={store}>
    <AuthContext.Provider value={contextConst}>
    <BrowserRouter basename="/">
    <React.Suspense fallback={loading}>
    <Routes>    
    <Route path="/signIn" element={<UnauthenticatedRoute element={<SignIn />} />}/>
    <Route path="/forget" element={<UnauthenticatedRoute element={<Forget />} />}/>
    <Route path="/change-pwd" element={<UnauthenticatedRoute element={<ChangePwd />} />} />
    <Route path="/sign-up" element={<UnauthenticatedRoute element={<SignUp />} />}/>
    </Routes>
    
    <AuthenticatedRoute element={<DefaultLayout />} />
    
    </React.Suspense>
    </BrowserRouter>
    </AuthContext.Provider>
    </Provider>
    );
};

export default App;