import { createStore, combineReducers, applyMiddleware } from 'redux'
import {thunk} from 'redux-thunk'
import LoginReducer from './views/pages/Sign_In/Reducer'

const appReducer = combineReducers({
  userdata: LoginReducer,
})

const rootReducer = (state, action) => {

  if (action.type === 'logout') {
    state = undefined;
  }
  console.log("store state",state);
  console.log("store action",action);
  return appReducer(state, action)
}

const store = createStore(rootReducer, applyMiddleware(thunk))
export default store